import React from 'react'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import styled from 'styled-components'
import SectionHeader from '@components/ui/typography/SectionHeader'
import TextImageHero from '@components/common/TextImageHero'
import { GatsbyImage } from 'gatsby-plugin-image'

const ExplainSection = ({ feature, explain, showHeader = true, intl }) => {
  return (
    <SectionWrapper>
      {showHeader && (
        <TitleWrapper>
          <SectionHeader
            title={intl.formatMessage({ id: feature.explainTitle })}
            description={intl.formatMessage({ id: feature.explainDescription })}
          />
        </TitleWrapper>
      )}
      {explain.map((item, index) => {
        return (
          <div style={{ marginTop: '50px' }} key={index}>
            <TextImageHero
              section
              reverse={item.reverse}
              label={item.label ? intl.formatMessage({ id: item.label }) : ''}
              title={intl.formatMessage({ id: item.title })}
              description={[intl.formatMessage({ id: item.text })]}
              image={<GatsbyImage image={item.image.childImageSharp.gatsbyImageData} alt={'image'} />}
            />
          </div>
        )
      })}
    </SectionWrapper>
  )
}

export default ExplainSection

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    max-width: 600px;
  }
`
